import React from 'react';
import { Link, graphql } from 'gatsby';
import showdown from 'showdown';
import Layout from '../components/layout';
import Definitions from '../common/definitions';

const partToRemoveInTarget = '/uploads'; // no trailing / !!!

const CityTemplate = ({ data }) => (
  <Layout>
    <h1>{data.strapiCities.CityName}</h1>
    <div>
    <Link to={Definitions.GalleryPagesBasePath.concat(
              getState(data.allStrapiStates, data.strapiCities.district.state).country.slug.toLowerCase(),'/',
              getState(data.allStrapiStates, data.strapiCities.district.state).slug.toLowerCase(),'/',
              data.strapiCities.district.slug.toLowerCase(),'/')}
              >zurück</Link>
    </div>
    <p>From: {data.strapiCities.PhotoYear}</p>
    <div dangerouslySetInnerHTML={{ __html: getDescriptionHtml(data.strapiCities.Description) }}></div>
    {loadImages(data.strapiCities.Photos, data.strapiCities.strapiId, data.strapiCities.district.id, data.strapiCities.district.state)}
    
  </Layout>
);

const getDescriptionHtml = (markdown) => {
  const converter = new showdown.Converter();
  return converter.makeHtml(markdown);
}


const getAltText = (strapiText) => {
  if (strapiText !== "") {
    return strapiText;
  } else {
    return 'Foto ohne Alternativtext';
  }
}

const getState = (stateList, stateId) => {
  for(let i = 0; i < stateList.edges.length; i++){
    if (stateList.edges[i].node.strapiId === stateId) {
      return stateList.edges[i].node;
    }
  }
  return null;
}

const getImagePathAndFile = (sourceUrlFromStrapi, cityId, districtId, stateId) => {
  const fileName = sourceUrlFromStrapi.replace(partToRemoveInTarget, '');
  return Definitions.GalleryPhotosBasePath.concat(stateId, '/', districtId, '/', cityId, fileName);
}

const loadImages = (imageList, cityId, districtId, stateId) => {
  // imageList.sort((a, b) => (a.name > b.name) ? 1 : -1).map((image)
  return (
    <div>{imageList.map((image) => {
      // get ids for city, district and state
      const cmsImage = getImagePathAndFile(image.formats.thumbnail.url, cityId, districtId, stateId);
      return (
        <div>
          <img
            src={cmsImage}
            width={image.formats.thumbnail.width}
            alt={getAltText(image.alternativeText)}
            style={{ marginBottom: `1.45rem` }}
          />
        </div>
      );}
    )}
    </div>
  );
}

export default CityTemplate;

export const query = graphql`
  query CityTemplate($id: String!) {
    strapiCities(id: {eq: $id}) {
      CityName
      PhotoYear
      Description
      Order
      strapiId
      district {
        DistrictName
        id
        slug
        state
      }
      Photos {
        alternativeText
        caption
        url
        width
        height
        name
        formats {
          thumbnail {
            url
            height
            width
          }
        }
      }
    }
    allStrapiStates {
      edges {
        node {
          strapiId
          id
          StateName
          slug
          country {
            CountryName
            slug
            id
          }
        }
      }
    }
  }
`;